import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/app/[locale]/_dowloadSection/DownloadSection.tsx");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/dev-site/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/Footer/CategoryCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/Footer/PropertyCategoriesSwiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/Footer/SupportLink.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/mainLayout/components/LangugueSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/mainLayout/components/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/mainLayout/components/NavbarAddBuildingSection.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/layout/mainLayout/components/ProfileNavSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/components/pages/login/Login.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/lib/jenkins/workspace/dev-site/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/dev-site/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/dev-site/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/dev-site/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/dev-site/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"IBM_Plex_Sans_Arabic\",\"arguments\":[{\"subsets\":[\"arabic\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"ibm\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/lib/jenkins/workspace/dev-site/node_modules/nextjs-toploader/dist/index.js");
